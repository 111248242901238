<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework Update Report: Show All Archives</h3>

    <p>The <strong>Show All Archives</strong> view of the <span v-html="link('framework_update', 'Framework Update Report')"></span> provides a list of archives for the selected framework with filtering and drill in options available:
        <br>
        <img alt="Framework Update Report: Show All Archives image" srcset="/docimages/show_all_archives.png 1.1x" class="k-help-img float-right">
        <ol class="k-no-clear">
            <li>The first column of the archives table can show when the <strong>Archive was Saved</strong> OR <strong>Framework Last Changed.</strong></li>
            <li>Checking the <strong>Show updates details box</strong> will reveal the item and association level data as well as the option to choose to compare the changes from <strong>archive to archive</strong> OR from the <strong>archive to the current framework.</strong></li>
            <li>Checking the box for <strong>Show all auto-archives</strong> will display a row for every auto-archive entry. <Strong>*NOTE:</Strong> Auto-archive entries are made after any user with edit rights enables editing, makes a change, and chooses to save the changes. The system will save the previous version of the framework as an auto-archive.</li>
            <li>Selecting the <strong>Table <nobr><v-icon small>fa-solid fa-table-list</v-icon></nobr></strong> or <strong>Track Changes <nobr><v-icon small>fa-solid fa-right-left</v-icon></nobr></strong> icons will provide you with a more detailed side-by-side view of the selected archive and the changes made.</li>
        </ol>
    
    </p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>